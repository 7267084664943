export const servicesImages = [
  {
    src: require("../../../images/servicesImgs/design.jpg"),
    alt: "design_example_image",
  },
  {
    src: require("../../../images/servicesImgs/tattoo.jpg"),
    alt: "tattoo_example_image",
  },
  {
    src: require("../../../images/servicesImgs/piercing.jpg"),
    alt: "piercing_example_image",
  },
  {
    src: require("../../../images/servicesImgs/microdermal.jpg"),
    alt: "microdermal_example_image",
  },
];
