export const slidesImages = [
  {
    src: require("../../../images/galleryCarouselImgs/10.jpg"),
    alt: "carouselExampleImg1",
  },
  {
    src: require("../../../images/galleryCarouselImgs/12.jpg"),
    alt: "carouselExampleImg2",
  },
  {
    src: require("../../../images/galleryCarouselImgs/13.jpg"),
    alt: "carouselExampleImg3",
  },
  {
    src: require("../../../images/galleryCarouselImgs/15.jpg"),
    alt: "carouselExampleImg4",
  },
  {
    src: require("../../../images/galleryCarouselImgs/22.jpg"),
    alt: "carouselExampleImg4",
  },
];
